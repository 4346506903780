<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="45%">
    <el-form ref="form" :model="formAdd" :rules="ruleUser">
      <el-form-item label="昵称" :label-width="formLabelWidth" prop="nickName">
        <el-input v-model="formAdd.nickName"  placeholder="请输入昵称" disabled></el-input>
      </el-form-item>
      <el-form-item label="真实姓名" :label-width="formLabelWidth" prop="realName">
        <el-input v-model="formAdd.realName"  placeholder="请输入真实姓名" disabled></el-input>
      </el-form-item>
      <el-form-item label="身份" :label-width="formLabelWidth" prop="roleName">
        <el-select v-model="formAdd.roleName" placeholder="请选择身份" disabled >
          <el-option
              v-for="item in cList"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系方式" :label-width="formLabelWidth" prop="userPhone">
        <el-input v-model="formAdd.userPhone"  placeholder="请输入电话" disabled></el-input>
      </el-form-item>
      <el-form-item label="邀请码" :label-width="formLabelWidth" prop="inviteCode">
        <el-input v-model="formAdd.inviteCode" autocomplete="off" placeholder="邀请码" disabled></el-input>
        <el-button type="text" @click="copy" :data-clipboard-text="formAdd.inviteCode" class="inviteCode">复制</el-button>
      </el-form-item>
    </el-form>
<!--    <div slot="footer" class="dialog-footer">-->
<!--      <el-button @click="close">取 消</el-button>-->
<!--      <el-button type="primary" @click="save">确 定</el-button>-->
<!--    </div>-->
  </el-dialog>
</template>

<script>
import Clipboard from 'clipboard'
import { getPList } from "@/utils/api/position";
import {getStore} from "@/utils/storage";

export default {
  name: "EditMember",
  data(){
    var checkPhone = (rule, value, callback)=>{
      let reg_mobile =/^1[3456789]\d{9}$/
      if (!reg_mobile.test(value) ){
        callback(new Error('请输入正确的手机或座机号码'));
      }else{
        callback();
      }
    }
    var checkName = (rule, value, callback) => {
      let reg = /^[a-zA-Z\u4e00-\u9fa5]+$/g;
      if (value.length > 20) {
        callback(new Error("输入的姓名过长"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入中文或英文"));
      } else {
        callback();
      }
    };
    return{
      dialogFormVisible:false,
      dialogFormVisible2:false,
      title:'新增',
      formLabelWidth:"100px",
      labelPosition:"right",
      cList:[],//身份列表
      inviteCode:"123135135432513",//邀请码
      formAdd:{
        nickName: '',//昵称
        roleName:'',//身份
        realName:"",//真实姓名
        userPhone:"",//联系方式
        inviteCode:"",//邀请码
      },
      ruleUser:{
        nickName: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          { validator: checkName, trigger: "blur" }
        ],
        roleName:[
          { required: true, message: "请选择身份", trigger: "blur" },
        ],
        realName:[
          { required: true, message: "请输入姓名", trigger: "blur" },
          { validator: checkName, trigger: "blur" }
        ],
        userPhone:[
          { required: true, message: '请输入电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
      }
    }
  },
  computed:{

  },
  created() {
    this.cList= getStore('identity')
  },
  methods:{
    showEdit(row) {
      this.title = '查看个人信息'
      this.formAdd = Object.assign({},this.formAdd, row)
      this.dialogFormVisible = true
    },

    close(){
      this.$refs["form"].resetFields();
      this.formAdd = this.$options.data().formAdd
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.dialogFormVisible = false
          this.$emit('save',this.formAdd)
          this.$refs["form"].resetFields();
          this.formAdd = this.$options.data().formAdd
        } else {
          return false
        }
      })
    },
    copy(){
      var clipboard = new Clipboard('.inviteCode')
      clipboard.on('success', e => {

        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e =>{
        // 不支持复制
        // 释放内存
        clipboard.destroy ()
      })
    },
  }
}
</script>

<style  lang="less" scoped>
.el-input{
  width: 60%;
}
.el-select{
  width: 60%;
  .el-input{
    width: 100%;
  }
}
</style>
