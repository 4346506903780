<template>
  <div class="handle-container">
    <div class="bread"><span @click="home()">操作台 / </span> <span class="gray">人员管理</span></div>
    <el-row class="table-body">
      <div class="line clearfix">
        <h5 class="fl title">人员管理</h5>
        <div class="fr">
          <el-button type="primary" size="small" v-if="pState==0 && showRouter('admin')"
                     @click="addNew">添加新成员
          </el-button>
          <el-button type="primary" size="small" v-if="pState==1" @click="addContact">添加默认联系人</el-button>
          <el-button type="primary" size="small" plain v-if="(this.$store.state.identity !== '超级管理员')"
                     @click="toLeave">退出企业
          </el-button>
          <!--          <el-button type="primary" size="small" plain-->
          <!--                     @click="toLeave">退出企业</el-button>-->
        </div>
      </div>
      <div class="list">
        <el-tabs v-model="pState" @tab-click="handleClick">
          <el-tab-pane label="人员管理" name="0" v-if="showRouter('admin')"></el-tab-pane>
          <el-tab-pane label="默认联系人" name="1"></el-tab-pane>
          <el-tab-pane :label="operator+'操作记录'" name="2" v-if="showOperate == true"></el-tab-pane>
        </el-tabs>
        <el-table v-if="pState==0 && showRouter('admin')"
                  :data="tableData1"
                  style="width: 100%">
          <el-table-column
              fixed
              type="index"
              label="序号"
              width="80">
          </el-table-column>
          <el-table-column
              prop="nickName"
              label="昵称"
              width="200">
          </el-table-column>
          <el-table-column
              prop="roleName"
              label="身份"
              width="200">
          </el-table-column>
          <el-table-column
              prop="realName"
              label="真实姓名"
              width="300">
          </el-table-column>
          <el-table-column
              prop="userPhone"
              label="手机号"
              min-width="300">
          </el-table-column>

          <el-table-column
              fixed="right"
              label="操作"
              width="200">
            <template slot-scope="scope">
              <el-button
                  v-if="scope.row.status==1"
                  @click="checkRecord(scope.row)"
                  type="text"
                  size="small">
                操作记录
              </el-button>
              <el-button
                  v-if=" showRouter('admin') && scope.row.roleName!=='超级管理员' "
                  @click="editOne(scope.row)"
                  type="text"
                  size="small">
                查看
              </el-button>
              <el-button
                  v-if=" showRouter('admin') && scope.row.roleName!=='超级管理员' && scope.row.status==1"
                  @click="toUnbind(scope.row)"
                  class="red"
                  type="text"
                  size="small">
                解绑
              </el-button>
              <el-button
                  v-if=" showRouter('admin') && scope.row.roleName!=='超级管理员' && scope.row.status!==1"
                  @click="toDelMember(scope.row)"
                  class="red"
                  type="text"
                  size="small">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pages" v-if="pState==0">
          <el-pagination
              background
              @current-change="handleCurrentChange1"
              :hide-on-single-page="showPage"
              layout="prev, pager, next"
              :page-size="pageSize1"
              :total="total1">
          </el-pagination>
        </div>
        <el-table v-if="pState==1"
                  :data="tableData2"
                  style="width: 100%">
          <el-table-column
              fixed
              type="index"
              label="序号"
              width="80">
          </el-table-column>
          <el-table-column
              prop="name"
              label="员工姓名"
              width="200">
          </el-table-column>
          <el-table-column
              prop="telePhone"
              label="手机号"
              min-width="200">
          </el-table-column>
          <el-table-column
              prop="default"
              label="是否默认"
              min-width="200">
            <template slot-scope="scope">
              <el-radio-group v-model="scope.row.defaultFlag" @change="changeDefault(scope.row)">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="200">
            <template slot-scope="scope">
              <el-button
                  @click="editContact(scope.row)"
                  type="text"
                  size="small">
                编辑
              </el-button>
              <el-button
                  @click="toUnbind2(scope.row)"
                  class="red"
                  type="text"
                  size="small">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-table v-if="pState==2"
                  :data="tableData3"
                  style="width: 100%">
          <el-table-column
              fixed
              type="index"
              label="序号"
              width="80">
          </el-table-column>
          <el-table-column
              prop="operUserName"
              label="昵称"
              width="200">
          </el-table-column>
          <el-table-column
              prop="roleName"
              label="用户身份"
              width="200">
          </el-table-column>
          <el-table-column
              prop="addTime"
              label="操作时间"
              width="200">
          </el-table-column>
          <el-table-column
              prop="content"
              label="操作内容"
              min-width="200"
              show-overflow-tooltip>
          </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :hide-on-single-page="showPage"
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total">
        </el-pagination>
      </div>
    </el-row>
    <edit-member ref="member" @save="saveMember"></edit-member>
    <edit-member2 ref="member2" @save="editMember"></edit-member2>
    <edit-contact ref="contact" @save="saveContact" @edit="changeContact"></edit-contact>
    <delete-confirm ref="delete" :content="content" :p-stata="pState" @save="unbind"></delete-confirm>
  </div>
</template>

<script>
import EditMember from "@/components/EditMember";
import EditMember2 from "@/components/EditMember2";
import EditContact from "@/components/EditContact";
import DeleteConfirm from "@/components/DeleteConfirm";
import {addUserInfo, editUser, getMList, deleteUser} from "@/utils/api/company";
import {getCList, addOne, toUpdate, delOne} from "@/utils/api/contact";
import {getLogList} from "@/utils/api/own";
import {mapGetters} from "vuex";
import {getStore} from "@/utils/storage";

export default {
  name: "customer",
  components: {
    EditMember,
    EditMember2,
    EditContact,
    DeleteConfirm
  },

  data() {
    return {
      currentUserId: '',
      total: 0, //总条数
      total1: 0, //总条数
      pageNo: 1,
      pageNo1: 1,
      pageSize: 10,
      pageSize1: 10,
      showPage: true,
      showOperate: false,
      operator: "",
      pState: 0,
      dealOne: "",
      content: "",
      tableData1: [ //人员管理
        {
          id: 1,
          nickName: "昵称",//昵称
          roleName: "",//身份
          realName: "名字",//真实姓名
          userPhone: "11111111111"//手机号码
        },
      ],
      tableData2: [ //默认联系人
        {
          cid: 1,
          realName: "名字",//员工姓名
          phone: "",//联系电话
          default: 1,//默认联系人
        }
      ],
      tableData3: [ //操作记录
        {
          cid: 1,
          nickName: '昵称',
          identity: "",//身份
          action_time: "2021.11.18 14:17:30",
          action: '超级管理员恒马小陈通过默认联系人添加了车管员工汪小菲，超级管理员恒马小陈通过默认联系人添加了车管员工汪小菲'
        }
      ],
      memberAdd: { //新增成员
        identity: '',//身份
        phone: "",//联系方式
        invite: "" //邀请码
      },
      memberEdit: { ///编辑成员
        nickName: "",//昵称
        identity: '',//身份
        realName: '',//真实姓名
        phone: "",//联系方式
      },
      memberDefault: { // 编辑默认联系人
        cid: "",
        name: "",
        phone: "",
      }
    }
  },
  computed: {
    ...mapGetters([
      "getPermissions"
    ])
  },
  created() {
    // if (this.showRouter('admin') == false) {
    //   this.pState = "1"
    //   this.getCList()
    // } else {
    //   this.getMList()
    //   this.getCList()
    // }

    this.getMList()
    this.getCList()

    this.currentUserId = this.$store.state.userInfo.userId
  },
  methods: {
    //删除未绑定成员
    toDelMember(row){
      this.$confirm("确认删除吗？", "取消", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      })
          .then( () => {
            let data = {
              "id": row.id,
              "unboundPhone": row.userPhone
            }
            deleteUser(data).then(res => {
              if(res.code === 0){
                this.$message.success("删除成功")
                this.getMList()
              }else {
                this.$message.error(res.msg)
              }
            })
          })
    },
    handleCurrentChange1(val) {
      this.pageNo1 = val;
      this.getMList()
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getMList()
    },
    handleClick() {
      // console.log(this.pState);
    },
    showRouter(permission) {
      if (permission === 'admin') {
        return true
      } else {
        // let result = this.$store.state.permissions.findIndex(item => item=== permission)
        let result = this.getPermissions.findIndex(item => item === permission)
        if (result != -1) {
          return true
        } else {
          return false
        }
      }
    },
    changeDefault(row) {
      toUpdate(row).then(res => {
        if (res.code == 0) {
          this.$baseMessage('设置默认联系人成功', 'success')
          this.pState = "1"
          setTimeout(() => {
            this.getCList()
          }, 1000)
        } else {
          this.$baseMessage(res.msg, 'error')
        }
      })
    },
    getMList() {
      let form = {
        status: [0, 1],
        rows: this.pageSize1,
        page: this.pageNo1,
      }
      getMList(form).then(res => {
        if (res.code === 0) {
          this.tableData1 = res.data.records
          this.total1 = Number(res.data.total)
        } else {
          this.$baseMessage(res.message, 'warning')
        }
      })
    },
    getCList() {
      let form = {
        status: ["1", '0'],
        // rows:this.pageNo,
        // totalPage:this.pageSize,
      }
      getCList(form).then(res => {
        if (res.code == 0) {
          this.tableData2 = res.data
          this.total = res.total
        } else {
          this.$baseMessage(res.msg, 'warning')
        }
      })
    },
    getLogList(user) {
      let form = {
        id: user,
        page: this.pageNo,
        rows: this.pageSize
      }
      getLogList(form).then(res => {
        if (res.code == 0) {
          // console.log(res.data)
          this.tableData3 = res.data.list
        }
      })
    },
    checkRecord(row) {
      if (this.showOperate == false) {
        this.showOperate = true
      }
      this.pState = "2"
      this.getLogList(row.id)
      this.operator = row.realName

    },
    checkRecord2(row) {
      if (this.showOperate == false) {
        this.showOperate = true
        this.pState = "2"
      }
      this.getLogList(row.id)
      this.operator = row.name

    },
    addNew() {
      this.$refs['member'].add()
    },
    editOne(row) {
      this.$refs['member2'].showEdit(row)
    },
    addContact() {
      this.$refs['contact'].add()
    },
    editContact(row) {
      this.$refs['contact'].add(row)
    },
    //新增客户
    saveMember(form) {
      addUserInfo(form).then(res => {
        if (res.code == 0) {
          this.$baseMessage('添加成功', 'success')
          setTimeout(() => {
            this.getMList()
          }, 1000)
        } else {
          this.$baseMessage(msg, 'error')
        }
      })
    },
    //编辑成员
    editMember(form) {
      editUser(form).then(res => {
        if (res.code == 0) {
          this.$baseMessage('修改成功', 'success')
          setTimeout(() => {
            this.getMList()
          }, 1000)
        } else {
          this.$baseMessage(res.msg, 'error')
        }
      })
    },
    //新增默认联系人
    saveContact(form) {
      addOne(form).then(res => {
        if (res.code == 0) {
          this.$baseMessage('成功添加默认联系人', 'success')
          setTimeout(() => {
            this.pState = "1"
            this.getCList()
          }, 100)
        } else {
          this.$baseMessage(res.msg, 'error')
        }
      })
    },
    changeContact(form) {
      toUpdate(form).then(res => {
        if (res.code == 0) {
          this.$baseMessage('修改默认联系人成功', 'success')
          setTimeout(() => {
            this.pState = "1"
            this.getCList()
          }, 100)
        } else {
          this.$baseMessage(res.msg, 'error')
        }
      })
    },
    toUnbind(row) {
      this.dealOne = row
      this.content = "是否将员工" + row.realName + "移出本企业"
      this.$refs['delete'].showEdit()
    },
    toUnbind2(row) {
      this.dealOne = row
      this.content = "是否将默认联系人" + row.name + "删除"
      this.$refs['delete'].showEdit()
    },

    toUnbind3(row){
      this.dealOne= row
      this.content = "是否将员工"+row.name+"删除"
      this.$refs['delete'].showEdit()
    },
    toLeave() {
      this.dealOne = {
        id: this.currentUserId

      }
      this.pState = '-1'
      this.content = "是否退出当前企业"
      this.$refs['delete'].showEdit()
    },
    unbind() {
      this.pageNo == 1
      if (this.pState == 0) {
        let form = {
          unboundPhone: this.dealOne.userPhone
        }
        deleteUser(form).then(res => {
          if (res.code == 0) {
            this.$baseMessage('解绑成功', 'success')
            setTimeout(() => {
              this.getMList()
            }, 100)

          } else {
            this.$baseMessage(res.msg, 'error')
          }

        })
      } else if (this.pState == 1) {
        let form = {
          id: this.dealOne.id
        }
        delOne(form).then(res => {
          if (res.code == 0) {
            this.$baseMessage('删除成功', 'success')
            setTimeout(() => {
              this.getCList()
            }, 100)
          } else {
            this.$baseMessage(res.msg, 'error')
          }
        })
      } else {
        let form = {
          id: this.dealOne.id
        }
        deleteUser(form).then(res => {
          if (res.code == 0) {
            this.$baseMessage('成功退出当前企业', 'success')
            setTimeout(() => {
              this.$router.push({'name': '登陆'})
            }, 100)
          } else {
            this.$baseMessage(res.msg, 'error')
          }
        })
      }
    },

  }
}
</script>

<style lang="less">
.table-body {
  background-color: #FFFFFF;
  padding: 30px;
  padding-top: 0;

  .line {
    height: 90px;
    line-height: 90px;

    .title {
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }

    .search {
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;

      .el-input {
        font-size: 12px;
        margin-right: 12px;


        .el-input__inner {
          border: none;
          background-color: #FAFAFA;
          padding-left: 50px;
        }

        .el-input__prefix {
          width: 40px;

          &:after {
            content: '';
            width: 1px;
            height: 15px;
            background-color: #D8D7D7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

</style>
