<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="45%">
    <el-form ref="form" :model="formAdd" :rules="ruleUser">
      <el-form-item label="身份" :label-width="formLabelWidth" prop="identity">
        <el-select v-model="formAdd.roleName" placeholder="请选择用户身份" @change="pickRoleName" filterable >
          <el-option
              v-for="item in getIdentity"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleName">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="昵称" :label-width="formLabelWidth" prop="nickName">
        <el-input v-model="formAdd.nickName"  placeholder="请输入昵称" ></el-input>
      </el-form-item>
<!--      <el-form-item label="真实姓名" :label-width="formLabelWidth" prop="realName">-->
<!--        <el-input v-model="formAdd.realName"  placeholder="请输入真实姓名" ></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="联系方式" :label-width="formLabelWidth" prop="userPhone">
        <el-input v-model="formAdd.userPhone" autocomplete="off" placeholder="请输入电话" ></el-input>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Clipboard from 'clipboard'
import {mapGetters} from "vuex";

export default {
  name: "EditMember",
  data(){
    var checkPhone = (rule, value, callback)=>{
      let reg_mobile =/^1[3456789]\d{9}$/
      if (!reg_mobile.test(value) ){
        callback(new Error('请输入正确的手机或座机号码'));
      }else{
        callback();
      }
    };
    var checkName = (rule, value, callback) => {
      let reg = /^[a-zA-Z\u4e00-\u9fa5]+$/g;
      if (value.length > 20) {
        callback(new Error("输入的姓名过长"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入中文或英文"));
      } else {
        callback();
      }
    };
    return{
      dialogFormVisible:false,
      dialogFormVisible2:false,
      title:'新增',
      formLabelWidth:"100px",
      labelPosition:"right",
      cList:[],//身份列表
      inviteCode:"123135135432513",//邀请码
      formAdd:{
        nickName: '',//昵称
        roleName:'',//身份
        // realName:"",//真实姓名
        userPhone:"",//联系方式
        realName:"",//真实姓名
        roleId:""
      },
      ruleUser:{
        roleName:[
          { required: true, message: "请选择身份", trigger: "blur" },
        ],
        nickName: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          { validator: checkName, trigger: "blur" }
        ],
        userPhone:[
          { required: true, message: '请输入电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        // realName:[
        //   { required: true, message: "请输入姓名", trigger: "blur" },
        //   { validator: checkName, trigger: "blur" }
        // ],
      }
    }
  },
  computed:{
    ...mapGetters([
        "getIdentity"
    ])
  },

  methods:{
    add() {
      this.title = '添加新成员'
      this.dialogFormVisible = true
    },
    close(){
      this.$refs["form"].resetFields();
      this.formAdd = this.$options.data().formAdd
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.dialogFormVisible = false
          this.$emit('save',this.formAdd)
          this.$refs['form'].resetFields()
          this.form = this.$options.data().form
        } else {
          return false
        }
      })
    },
    copy(){
      var clipboard = new Clipboard('.inviteCode')
      clipboard.on('success', e => {

        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e =>{
        // 不支持复制
        // 释放内存
        clipboard.destroy ()
      })
    },
    pickRoleName(rid){
      // let obj = {};
      // obj =  this.cList.find((item)=>{//这里的userRoleList就是上面遍历的数据源
      //   return item.roleId == rid;//筛选出匹配数据
      // });
      // this.formAdd.roleName = obj.roleName;
      this.getIdentity.map(item=>{
        if(item.roleName = rid){
          this.formAdd.roleId = item.roleId
        }
      })
    }
  }
}
</script>

<style  lang="less" scoped>
.el-select{
  width: 60%;
  .el-input{
    width: 100%;
  }
}
.el-input{
  width: 60%;
}
</style>
